.banner-area {
    padding-top: 350px;
    padding-bottom: 0px;
    position: relative;
    z-index: -1;
    // height:100%;
    // box-shadow: 0 0 18px 18px black inset;


    .banner-bg {
        z-index: -1;
        position: absolute;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;

        video {
            width: 100%;
        }
    }

    .banner-text {
        h1 {
            font-size: 50px;
        }
    }

    .btn-lg {
        background-image: url('../../images/button-bg.png');
    }

    li {
        .item {
            .h4 {
                font-weight: 600;
            }
        }
    }
}

.banner-bil {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(46px);
    align-items: center;
    margin: 0 auto;
    margin-bottom: 18rem;
    margin-top: 120px;
    max-width: 350px;

    .countdown {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        color: white;
    }

    .bil-item {
        padding: 30px;
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 1px;
            height: 60%;
            background: #D9D9D9;
            opacity: 0.2;

        }
    }

    h4,
    .h4 {
        padding: 10px 0;
        font-weight: 700 !important;
        font-size: 28px;
    }
}

.banner-shape {
    .shape1 {
        position: absolute;
        width: 480px;
        height: 467px;
        background: #AD1AAF;
        opacity: 0.75;
        filter: blur(259px);
        top: 32%;
        left: 0;
        z-index: -1;
    }

    .shape2 {
        position: absolute;
        width: 480px;
        height: 467px;
        background: #AD1AAF;
        opacity: 0.75;
        filter: blur(221px);
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}

.down-arrow {
    position: relative;

    a {
        width: 68px;
        height: 68px;
        background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        border-radius: 100px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 45px;
    }
}

@media (max-width: 991.98px) {
    .banner-bil {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 42px;
        margin-bottom: -419px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        z-index: 25;

        .bil-item {
            text-align: center;
            padding: 30px 0;
            position: relative;
            margin: 0 40px;

            &:nth-child(1),
            &:nth-child(3) {
                order: 1;
            }

            &::before {
                top: 0;
                width: 100%;
                height: 1px;
                display: none;
            }
        }

        .border-phone {
            border-top: .5px solid rgba(255, 255, 255, 0.295);
            border-bottom: .5px solid rgba(255, 255, 255, 0.295);
        }
    }

    .down-arrow {
        display: none;
    }

    .banner-area .banner-text h1 {
        font-size: 35px;
    }

    .banner-shape {
        display: block !important;

        .shape1 {
            width: 75px;
            height: 657px;
            background: #AD1AAF;
            opacity: 0.75;
            filter: blur(229px);
            right: 0;
            left: auto;
            top: 123px;
        }
    }

}

@media (max-width: 575.98px) {

    .banner-area {

        .banner-bg {

            video {
                width: 300%;
                margin-left: 50vw;
                transform: translate(-50%);
            }
        }
    }

    .banner-area .banner-text h1 {
        font-size: 32px;
    }

    .banner-shape .shape2 {
        width: 150px;
        height: 699px;
        background: #AD1AAF;
        filter: blur(149px);
    }
}

.ilo-btn{
    height: 30px;
    background-color: red;
    z-index: 20;
}

.press-item{
    padding: 30px;
    max-height: 100%;
    display: block;
    align-self: center;
    justify-content: block;
    position: relative;
    z-index: 11;
}
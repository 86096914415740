.call-action-area {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    box-shadow: 0 0 18px 18px black inset;
    padding: -75px 0 0;
    position: relative;
    z-index: 1;
    margin-top: 0px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 22.53%, #000000 90.8%);
        z-index: -1;
    }
}

.call-form {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #AD1AAF;
    backdrop-filter: blur(20px);
    border-radius: 15px;
    display: flex;
    padding: 5px;
    // margin-right: 25%;

    input {
        width: 100%;
        background: none;
        border: 0;
        box-shadow: none !important;
        color: white;
        outline: none;
        padding: 0 30px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        border-radius: 15px;
        &::placeholder{
            color: white;
        }
    }

    button {
        background: #AD1AAF;
        border-radius: 15px;
        height: 80px;
        width: 90px;
        border: 0;
    }
}

.img-partners{
    margin-top: 10%;;
    width: 100%;
}

.scroll-content{
    position: relative;
    text-align: right;
    padding: 60px 0 40px;
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(173, 26, 175, 0.2) 0%, #AD1AAF 5.6%, #AD1AAF 53.59%, #AD1AAF 85.88%, rgba(173, 26, 175, 0.2) 100%);
        width: 100%;
        height: 1px;
    }
}

@media (max-width: 991.98px){
    .call-form button { 
        height: 55px;
        width: 75px;
        border: 0;
        img{
            height: 9px;
        }
    }
    .scroll-content { 
        text-align: center; 
    }
}
.hunt-process-area {
    //background-image: url('../../images/process-bg.png');
    background-image: url('https://ik.imagekit.io/yh/process-bg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665606058395');

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0px 0;
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #000000 13.94%, rgba(0, 0, 0, 0) 56.05%, #000000 93.12%);
        z-index: -1;
    }
    &::after{
        content: '';
        position: absolute;
        top: -15%;
        left: 0;
        width: 100%;
        height: 115%;
        z-index: -1;
        //background-image: url('../../images/pngwing.png');
        background-image: url('https://ik.imagekit.io/yh/pngwing.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665606073743');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
    }
}

.process-content {
    display: grid;
    position: relative;
    margin-top: 50px;
    &::before{
        content: '';
        background: linear-gradient(180deg, rgba(173, 26, 175, 0.2) 0%, #AD1AAF 5.6%, #AD1AAF 53.59%, #AD1AAF 85.88%, rgba(173, 26, 175, 0.2) 100%);
        height: 100%;
        width: 2px;
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
    }

    .process-item {
        max-width: 460px;
        text-align: center;
        position: relative;
        &::before{
            content: "";
            background-color: #AD1AAF;
            height: 2px;
            width: 76%;
            position: absolute;
            left: 65%;
            top: 69px;
            z-index: -1;
            z-index: 1;
        }
        &:nth-child(even) {
            float: right;
            margin-left: auto;
            &::before{ 
                left: auto;
                right: 65%; 
            }
        }

        .number {
            width: 140px;
            height: 140px;
            border: 4px solid #AD1AAF;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #AD1AAF;
            font-weight: 600;
            font-size: 55px;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        p {
            color: white;
            font-weight: 400;
            font-size: 25px;
            line-height: 1.7;
        }
    }
}
@media (max-width: 1399.98px){
    .process-content .process-item::before { 
        width: 56%; 
    }
}
@media (max-width: 991.98px){
    .process-content::before{
        display: none;
    }
    .process-content .process-item::before{ 
        background: linear-gradient(180deg, rgba(173, 26, 175, 0.2) 0%, #AD1AAF 5.6%, #AD1AAF 53.59%, #AD1AAF 85.88%, rgba(173, 26, 175, 0.2) 100%);

        height: 87px;
        width: 2px;  
        top: auto; 
        z-index: 1;
        bottom: -108px;
        right: 0 !important;
        left: 0 !important;
        margin: 0 auto;
    }
    .process-content .process-item:last-child::before{
        display: none;
    }
    .process-content { 
        gap: 140px;
    } 
} 

.setting-area {
    background-image: url('https://ik.imagekit.io/yh/game-setting-bg-1198.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666804881781');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -210px;
    padding-bottom: 60px;
    padding-top: 3rem;
    position: relative;
    z-index: 12;
    // box-shadow: 0 32px 0 0  black inset;
}



.setting-slider-content {
    margin-top: 26px;
    p {
        color: white;
        font-weight: 400;
        font-size: 26px;
        line-height: 1.9;
    }

    .slick-dots {
        display: grid !important;
        top: 0;
        left: -100px;
        background: linear-gradient(180deg, #ffffff33 0%, rgba(217, 217, 217, 0.212) 100%); 
        width: 2px;
        height: 110%;

        li {
            margin: 0;
        }

        .slick-active {
            background: #AD1AAF;
            width: 4px;
            left: -1px;
            height: 100%;
            opacity: 1;
        }
    }
    .setting-item {
        padding-bottom: 18px;
    }
    
}

.nextButton{
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 400;
    font-size: 16px;
    margin-left: auto;

    span{
        width: 100%;
        height: 60px;
        background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
        backdrop-filter: blur(20px); 
        border-radius: 100px;
        padding: 17px 0;
    }
    img{  
        height: 10px;
    }
}

@media (max-width: 991.98px){
    .setting-area { 
        margin: 454px 0 0;
        padding-bottom: 30px;
        background: none;
    }
    .setting-slider-content {
        margin-top: 30px;
    }
    .setting-slider-content {
        .slick-dots { 
            left: 0;
            top: auto; 
            width: 100%;
            height: 2px;
            bottom: -90px;
            display: flex !important;
            li{
                width: 100% !important;
            }
        } 
        .setting-item {
            padding-bottom: 30px;
        }
    } 
}
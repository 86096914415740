.footer-area{

}

.footer-content{
    display: flex;
    align-items: center;
    padding: 80px 0;
    .footer-list{
        padding-right: 50px;
        a{
            color: white;
        }
    }
    .footer-social{
        a{
            color: white;
            font-size: 25px;
        }
    }
}

@media (max-width: 991.98px){
    .footer-content{
        display: grid;
        text-align: center;
        justify-content: center;
        .footer-list{
            padding: 40px 0;
        }
    } 
    
    .footer-list,
    .footer-social{
        justify-content: center;
    }

    .footer-center{
        width: 100%;
    }
   
}
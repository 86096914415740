.partner-item {
    padding: 16px;
    max-height: 100%;
    display: block;
    align-self: center;
    position: relative;
    z-index: 11;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        align-self: center;
        width: 100%;
      }
}

.press-item {
  padding: 25px;
  max-height: 100%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 11;
  img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      align-self: center;
      width: 100%;
    }
}





// .partner-slid {
//     .slick-slider {
//         margin: 0 -0px;
//     }

//     .slick-slide {
//         padding: 30px;
//     }

//     .partner-item {
//         text-align: center;
//         padding: 0 30px;
//         background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
//         border-width: 2.5px 0px;
//         border-style: solid;
//         border-color: rgba(255, 255, 255, 0.4);
//         backdrop-filter: blur(35.83px);
//         border-radius: 41.6667px;
//         margin-top: 30px;
//         max-height: 60px;

//         .icon {
//             margin-top: -130px;
//         }

//         img {
//             max-width: 100%;
//         }
//         }
//     }

// .partner-shape {
//     .shape1 {
//         position: absolute;
//         top: -41%;
//         left: 50%;
//         min-width: 863.24px;
//         height: 1000px;
//         background: #AD1AAF;
//         opacity: 0.5;
//         filter: blur(458.333px);
//         z-index: -1;
//         transform: translateX(-50%);
//     }

//     .shape2 {
//         position: absolute;
//         top: 0;
//         right: 0;
//         min-width: 505px;
//         height: 636px;
//         background: #8C30F5;
//         opacity: 0.5;
//         filter: blur(326.333px);
//         transform: matrix(0.31, 0.92, -1.01, 0.21, 0, 0);
//         z-index: -1;
//     }
//     .shape3 {
//         position: absolute;
//         top: 0;
//         left: 0;
//         min-width: 505px;
//         height: 636px;
//         background: #8C30F5;
//         opacity: 0.5;
//         filter: blur(326.333px);
//         transform: matrix(0.31, 0.92, -1.01, 0.21, 0, 0);
//         z-index: -1;
//     }
// }


// @media (max-width: 991.98px){
    
//     .partner-area {
//         padding: 70px 0 150px; 
//     }
//     .partner-slid .partner-item { 
//         padding: 0; 
//     }
//     .partner-slid .partner-item ul { 
//         padding: 0 35px;
//     }
// }


// .partner-slid{
//     .slick-prev, .slick-next{
//         top: auto;
//         bottom: -100px;
//         z-index: 1;
//         width: 60px;
//         height: 60px;
//         background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
//         backdrop-filter: blur(20px);
//         border-radius: 100px;
//         padding-bottom: 10px;
//         &::before{
//             filter: invert(42%) sepia(97%) saturate(2792%) hue-rotate(287deg) brightness(90%) contrast(100%);

//         }
//     }
//     .slick-prev {
//         left: 30%;
//         &::before{
//             content: url('../../images/prev-btn.png');
//         }
//     }
//     .slick-next {
//         right: 30%;
//         &::before{
//             content: url('../../images/next-btn.png');
//         }
//     }
//     .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
//         opacity: 1;
//         filter: invert(25%) sepia(0%) saturate(7484%) hue-rotate(157deg) brightness(107%) contrast(104%);
//     }
     
// }

// .partnerLogo{
//     max-height: 100%;
//     max-width: 100%;
// }
.game-teaser-area {
    position: relative;
    height: 100%;

    .game-teaser-bg {


        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        .game-teaser-bg-shadow {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // box-shadow: 0 0 18px 18px black inset;
            box-shadow: 0px -84px 72px 22px black inset;
            z-index: 1;
        }

        video {
            width: 100%;
            position: absolute;
            bottom: 0;
        }
    }


}

.game-item {
    cursor: pointer;
}

@media (max-width: 575.98px) {

    .game-teaser-area {
    
        .game-teaser-bg {
            video {
                width: 300%;
                margin-left: 50vw;
                transform: translate(-50%);
            }
        }
    }

}